import { PageProps } from 'gatsby';
import React from 'react';

import IndexPage from '../sections/IndexPage';
import { PostcodeProvider } from '../utils/usePostcode';

export default function Index(pageProps: PageProps): JSX.Element {
  return (
    <PostcodeProvider>
      <IndexPage {...pageProps} />
    </PostcodeProvider>
  );
}
